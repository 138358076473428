export default class ModalVideo {
  constructor () {
  }

  init () {
    (function ($) {
      function init() {
        youtube_modal();
        vimeo_modal();
      }

      function youtube_modal() {
        if ($(".youtube-button").length && typeof($(".youtube-button").modalVideo === 'function') ) {
            $(".youtube-button").modalVideo({
              channel: 'youtube',
              autoplay: 1,
              controls: 0,
              nocookie: false
            });
        }
      }

      function vimeo_modal() {
        if ($(".vimeo-button").length && typeof($(".vimeo-button").modalVideo === 'function') ) {
            $(".vimeo-button").modalVideo({
              channel: 'vimeo',
              autoplay: true,
              controls: false

            });
        }
      }

      $(init);
    })(jQuery);
  }
}