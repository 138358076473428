export default class CustomTabs {
  constructor(tabsContainer, tabSelect) {
    this.tabsContainer = tabsContainer
    this.tabs = this.tabsContainer.querySelectorAll('[data-bs-toggle=tab]')
    this.tabSelect = tabSelect
    this.tabInstances = []
  }

  init() {
    // When the select changes, show the requested tab pane
    this.tabSelect.addEventListener('change', (event) => {
      // Tried to do this with Tab.getOrCreateInstance(element) at first.
      // This worked, but importing Tab from bootstrap somehow stopped all Collapses/Dropdowns from working.
      this.tabs[event.target.value].click()
    })
    // When a tab has been clicked, update the select to match, in case the user flips between mobile and desktop views
    this.tabs.forEach((tab) => {
      tab.addEventListener('shown.bs.tab', (tab) => {
        const tabID = tab.currentTarget.getAttribute('id')
        this.tabSelect.value = tabID.slice(12, tabID.length)
      })
    })
  }
}
