export default class SearchAndFilters {
  constructor(forms) {
    // this.forms = $(forms)
    this.forms = document.querySelectorAll(forms)
  }

  init() {
    if (this.forms.length) {
      this.forms.forEach((form) => {
        let dropdowns = form.querySelectorAll('.dropdown-item')
        if (dropdowns.length) {
          dropdowns.forEach((dropdown) => {
            let labels = dropdown.querySelectorAll('label')
            if (labels.length) {
              labels.forEach((label) => {
                label.addEventListener('click', this.handleFilterClick)
              })
            }
          })
        }
      })
    }
  }

  handleFilterClick(e) {
    // Stop labels in the search form from closing the dropdown they're in when you click them,
    // so that user can select several of them
    e.stopPropagation()
  }
}
