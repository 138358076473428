import './vendor/jquery-modal-video.min'
import './vendor/modal-video.min'
import Forms from './components/Forms'
import CustomTabs from './components/CustomTabs'
import SearchAndFilters from './components/SearchAndFilters'
import ModalVideo from './components/ModalVideo'
import TimezoneConverter from './components/TimezoneConverter'
import ProfilePressAjax from './components/ProfilePressAjax'
import FindASession from './components/FindASession'
import {Collapse, Dropdown, Tab} from 'bootstrap'
window.bootstrap = {Collapse, Dropdown, Tab};
document.addEventListener('DOMContentLoaded', () => {


  // Bootstrap forms.
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = new Forms(document.getElementsByClassName('needs-validation'))
  forms.init()

  // Tabs on (initially!) the Resources page
  const resourceTabsContainer = document.getElementById('resource-tabs')
  const resourceTabsSelect = document.getElementById('resource-tabs-select')
  if (resourceTabsContainer && resourceTabsSelect) {
    const customTabs = new CustomTabs(resourceTabsContainer, resourceTabsSelect)
    customTabs.init()
  }

  // Main search page
  const searchAndFilters = new SearchAndFilters('.form--search--extended')
  searchAndFilters.init()


    // Modal video from jQuery plugin
    const modalVideo = new ModalVideo()
    modalVideo.init()

    // Timezone converter
    const timezoneConverter = new TimezoneConverter()
    timezoneConverter.init()

    // ProfilePress plugin fixes
    const profilePressAjax = new ProfilePressAjax()
    profilePressAjax.init()

    // FindASession dropdown js
    const findASession = new FindASession()
    findASession.init()

    //reset page scroll back to top of the page, works when you open an accordion with lots of text in it
    jQuery('.accordion').on('shown.bs.collapse', function(event) {
      jQuery('html, body').animate({
        scrollTop: jQuery(event.target).parent().offset().top - 28
      }, 0);
    });

    //handle password input fields, show eye plain text password toggle
    //needs this html <span toggle="#current_password" class="fa fa-fw fa-eye field-icon toggle-password"></span>
    jQuery(".toggle-password").click(function () {

        jQuery(this).toggleClass("fa-eye fa-eye-slash");
        var input = jQuery(jQuery(this).attr("toggle"));
        if (input.attr("type") == "password") {
            input.attr("type", "text");
        } else {
            input.attr("type", "password");
        }
    });

    //export GA vars
    jQuery(document).ready(function($) {
        // Assign values to window object
        window.aqa_user_center = php_vars.aqa_centre_number;
        window.aqa_user_center_name = php_vars.aqa_centre_name;

        // Debugging - Log to console
        console.log(window.aqa_user_center, window.aqa_user_center_name);
    });

  });



