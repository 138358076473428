export default class TimezoneConverter {
    constructor() {
    }

    init() {
        (function ($) {
            function init() {

                var $thisTz = jQuery('.timezone-converter');
                const timeZones = moment.tz.names();
                var tzOptions = {};
                for (const timezone of timeZones) {
                    tzOptions[timezone] = timezone;
                }

                var $thisFromTz = $thisTz.find('.timezone-converter-select-from-tz');
                var $thisToTz = $thisTz.find('.timezone-converter-select-to-tz');
                var $thisFromDateTime = $thisTz.find('.local-datetime-from');
                var $thisToDateTime   = $thisTz.find('.local-datetime-to');
                // "placeholder" option
                var thisOption = new Option(pll_translation.SelectFromTimezone, "");
                $thisFromTz.append(thisOption);
                var thisOption = new Option(pll_translation.SelectToTimezone, "");
                $thisToTz.append(thisOption);

                $.each(tzOptions, function (val, text) {
                    // var tzOffset = moment.tz([2012, 0], text).format('Z z'); // -07:00 MST
                    text = text.replace('_', ' ');// America/Los_Angeles
                    text = text.replace('/', ' / ');// America/Los_Angeles
                    // text += " (" + tzOffset + ")";

                    var thisOption = new Option(text, val);
                    $thisFromTz.append(thisOption);
                    var thisOption = new Option(text, val);
                    $thisToTz.append(thisOption);
                });

                var tzSelectTzWidth = $thisToTz.css('width');
                $thisFromDateTime.attr('style', 'width:' + tzSelectTzWidth + '!important; ');
                $thisToDateTime.attr('style', 'width:' + tzSelectTzWidth + '!important; ');

                $('.select2').each(function() {
                  $(this).select2({
                    dropdownParent: $(this).parent()
                  });
                });

                //fix bug with select2 not focusing on open
                $(document).on('select2:open', () => {
                    document.querySelector('.select2-search__field').focus();
                });

                var localUserDateTime = moment().format('YYYY-MM-DDTH:mm');
                $thisFromDateTime.val(localUserDateTime);

                // bind "onchange" for the from timezone, to timezone dropdown + input datetime
                $thisToTz.add($thisFromTz).add($thisTz.find('.local-datetime-from')).on('change', function () {
                    var thisFromTz = $thisFromTz.find(":selected").val();
                    var thisToTz = $thisToTz.find(":selected").val();
                    localUserDateTime = $thisTz.find('.local-datetime-from').val();
                    if (thisFromTz && thisToTz) {
                        var localMomentFromTime = moment.tz(localUserDateTime, thisFromTz);
                        var localMomentToTime = localMomentFromTime.clone().tz(thisToTz);
                        $thisToDateTime.val(localMomentToTime.format('DD/MM/YYYY H:mm'));
                    }
                });
            }

            $(init);
        })(jQuery);
    }
}
