export default class FindASession {
    constructor() {
    }

    init() {
        (function ($) {
            function init() {

                jQuery('.bs-multiselect').multiselect({
                    numberDisplayed: 0,
                    includeSelectAllOption: true,
                    selectAllText: 'All',
                    selectAllName: 'select-all-name',
                    buttonText: function(options, select) {
                        //console.log(this);
                        //console.log(select);
                        if (options.length == 0) {
                            return select.attr('data-btn-title');
                        }
                        else if (options.length > 0) {
                            return options.length + ' selected';
                        }
                        //buttontext
                    },
                    templates: {
                      button: '<button type="button" class="multiselect form-select" data-bs-toggle="dropdown" aria-expanded="false"><span class="multiselect-selected-text"></span></button>',
                    },
                });

                jQuery('.btn-group').on('click', function(e){
                    const mediaQuery = window.matchMedia('(max-width: 650px)');
                    // Check if the media query matches
                    if (mediaQuery.matches) {
                      //Mobile device with a maximum width of 650px or less
                      jQuery(this).find('.multiselect-container.dropdown-menu').width(jQuery(this).width());
                    } else {
                      jQuery(this).find('.multiselect-container.dropdown-menu').width(jQuery(this).width()+20);
                    }

                });

                $(window).on('resize', collapseFiltersOnMobile);
                collapseFiltersOnMobile();

            }


            function collapseFiltersOnMobile(e) {
                const mediaQuery = window.matchMedia('(max-width: 650px)');
                    // Check if the media query matches
                if (mediaQuery.matches) {
                    const collapseContent = new bootstrap.Collapse(document.getElementById('collapseFindSessionContent'), {
                      toggle: false
                    });
                }
            }

            $(init);
        })(jQuery);
    }
}
