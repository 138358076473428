export default class Forms {
  constructor (forms) {
    this.forms = forms
  }

  init () {
    // Loop over all the forms we want to apply custom Bootstrap validation styles to and prevent submission
    Array.prototype.filter.call(this.forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault()
          event.stopPropagation()
          console.log('checkValidity has failed');
        } else {
          let submitButton = form.querySelector('button[type="submit"]')
          if (submitButton) {
            submitButton.setAttribute('disabled', 'disabled')
          }
        }
        form.classList.add('was-validated')
      }, false)
    })
  }
}
