export default class ProfilePressAjax {
    constructor() {
    }

    init() {
        (function ($) {
            function init() {
                var nonceRefreshRunning = false;

                $('form[data-pp-form-submit="editprofile"]').find('input[name="eup_submit"]').on('click mouseover hover mousedown mousemove', function (){
                    try {

                        if (nonceRefreshRunning === true)
                            return;

                        nonceRefreshRunning = true;
                        //update the profilepress form with new nonce each time its submitted.
                        // the plugin is bugged so fixing it with this.
                        var data = [];
                        data.push({
                          'name': 'action',
                          'value': 'aqa_user_profilepress_filter_handler'
                        });
                        data.push({
                          'name': 'sub-action',
                          'value': 'renew-nonce'
                        });

                        $.ajax({
                            url: window.ajaxurl.ajaxurl,
                            dataType: "json",
                            data: $.param(data),
                            type: "POST",
                            success: function success(response) {
                                if (response.type === "success") {
                                    if(typeof pp_ajax_form !== 'undefined' && pp_ajax_form && pp_ajax_form.nonce){
                                        pp_ajax_form.nonce = response.nonce1;
                                    }
                                    $('#_wpnonce').val(response.nonce2);
                                } else {
                                    console.log("Couldn't get a response");
                                }
                                nonceRefreshRunning = false;
                            },
                            error: function (request, status, error) {
                                nonceRefreshRunning = false;
                            },
                            complete: function (data) {
                             nonceRefreshRunning = false;
                            }
                        });

                    } catch (e) {
                        if (typeof console !== 'undefined')
                            console.error(e);

                        nonceRefreshRunning = false;
                    }
                }).on('click', function() {
                    // when updating multiple times the same form, this text stays behind, remove it
                    jQuery('.pp-edit-profile-form-wrap').find('h3:contains("Changes saved.")').remove();
                });
            }

            window.pp_checkPasswordStrength = function ($pass1, $pass2, $strengthResult, $submitButton, blacklistArray) {
                var min_password_strength = 4;
                var pass1 = $pass1.val();
                var pass2 = $pass2.val();

                // Reset the form & meter
                $submitButton.attr('disabled', 'disabled').css("opacity", ".4");
                $strengthResult.removeClass('short bad good strong');

                // Extend our blacklist array with those from the inputs & site data
                blacklistArray = blacklistArray.concat(wp.passwordStrength.userInputBlacklist());

                // Get the password strength
                var strength = 0;
                if (pass1 && pass1.length < 6) {
                    $strengthResult.addClass('bad').html("Minimum password length is 6 characters");
                    return;
                }

                if (pass1 && pass1.length > 24) {
                    $strengthResult.addClass('bad').html("Maximum password length is 24 characters");
                    return;
                }
                if (!pass1.match(/[A-Z]+/)) {
                  strength = 1;
                }
                else if (!pass1.match(/[0-9]+/)) {
                  strength = 2;
                }
                else if (!pass1.match(/[$@#&!]+/)) {
                  strength = 3;
                }
                else if (pass1 && pass2 && pass1 !== pass2) {
                    strength = 5;
                }
                else {
                    strength = 4;
                }

                // Add the strength meter results
                switch (strength) {
                    case 0:
                        $strengthResult.addClass('bad').html(pwsL10n.bad);
                        break;
                    case 1:
                        $strengthResult.addClass('bad').html("Passwords must contain at least one uppercase character");
                        break;
                    case 2:
                        $strengthResult.addClass('short').html("Passwords must contain at one number");
                        break;
                    case 3:
                        $strengthResult.addClass('good').html("Passwords must contain at least one symbol");
                        if (min_password_strength === 3) {
                            pass_strength = 1;
                        }
                        break;
                    case 4:
                        $strengthResult.addClass('strong').html(pwsL10n.strong);
                        if (min_password_strength === 4) {
                            pass_strength = 1;
                        }
                        break;
                    case 5:
                        $strengthResult.addClass('bad').html(pwsL10n.mismatch);
                        break;
                    default:
                        $strengthResult.addClass('short').html("Password is too short");
                }

                // The meter function returns a result even if pass2 is empty,
                // enable only the submit button if the password is strong
                if (strength !== 5 && min_password_strength <= strength) {
                    $submitButton.removeAttr('disabled').css("opacity", "");
                }

                return strength;
            }

            $(init);
        })(jQuery);
    }
}
